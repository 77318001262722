<template>
  <li v-if="allProducts.length" class="s-l-cards__item">
    <div class="s-card" :class="{ 's-card--favorite': location.isFavourite }">
      <span class="s-card__fav-tag" v-if="location.isFavourite">
        <span class="d-block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14.7 14"
            width="14.7"
            height="14"
            aria-hidden="true"
            focusable="false"
            role="img"
            class="d-block"
          >
            <path
              fill="#FFF"
              d="M6.5.5L4.8 4.1l-4 .6c-.5.1-.9.5-.8 1 0 .2.1.4.3.5L3.2 9l-.7 4c-.1.5.2.9.7 1 .2 0 .4 0 .6-.1L7.4 12l3.6 1.9c.4.2 1 .1 1.2-.4.1-.2.1-.4.1-.6l-.7-4 2.9-2.8c.3-.3.4-.9 0-1.2-.1-.1-.3-.2-.5-.3L10 4 8.1.5C7.9.1 7.4-.1 6.9.1c-.1.1-.3.2-.4.4z"
            />
          </svg>
        </span>
        <span class="s-card__fav-text"> Oblíbené </span>
      </span>

      <div class="s-card__header">
        <h3 class="s-card__title">
          {{ location.city }} <span class="s-card__subtitle">/ {{ location.location }}</span>
        </h3>
      </div>
      <div class="s-card__address">
        <h4 class="s-u-visualy-hidden">Adresa pobočky</h4>
        <span class="s-card__address-ico">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12.2 16.3"
            width="12.2"
            height="16.3"
            aria-hidden="true"
            focusable="false"
            role="img"
            class="d-block"
          >
            <path
              fill="#F0AB69"
              d="M5.5 16C.9 9.3 0 8.6 0 6.1 0 2.7 2.7 0 6.1 0s6.1 2.7 6.1 6.1c0 2.5-.9 3.2-5.5 9.9-.2.3-.7.4-1.1.2 0-.1-.1-.2-.1-.2zm.6-7.3c1.4 0 2.5-1.1 2.5-2.5S7.5 3.6 6.1 3.6 3.6 4.7 3.6 6.1s1.1 2.6 2.5 2.6z"
            />
          </svg>
        </span>
        <span class="s-card__address-text"> {{ location.address }} </span>
      </div>
      <div class="s-card__tags">
        <h4 class="s-u-visualy-hidden">Na této pobočce provádíme následující testy</h4>
        <span class="s-card__tag" v-for="product in products(location.id)" :key="product.id">
          {{ product.name }}
        </span>
      </div>
      <p class="s-card__text">
        Pro následující <strong>3 dny</strong> volných ještě&nbsp;<strong
          >{{ location.freeSlotsCount ? ' ' + location.freeSlotsCount : 0 }} míst</strong
        >.
      </p>
      <button class="s-btn" type="button" @click="pickLocation">Vybrat</button>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TranlastionMixin from '../../mixins/TranslationMixin';

import { ProductModel } from '../../common/models/product.model';

import { XiteApiService } from '../../services/xite.api.service';

export default {
  name: 'LocationCardComponent',

  mixins: [TranlastionMixin],

  props: {
    location: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      products: 'products/getAllLocationProducts',
      allProducts: 'products/getAllProducts',
    }),
  },

  methods: {
    ...mapActions('orders', ['updateFormData']),
    ...mapActions('products', ['setProducts']),

    getProductFiles() {
      XiteApiService.get('Products', {
        instance: process.env.VUE_APP_INSTANCE_FILE,
        location: this.location.id,
      }).then(files => {
        this.getProductData(files['item']).then(products => {
          this.setProducts({
            products: products.map(product => new ProductModel(product)),
            locationId: this.location.id,
          });
        });
      });
    },

    getProductData(files) {
      let promises = [];
      return new Promise(resolve => {
        for (let file of files) {
          promises.push(
            XiteApiService.get('GetItems', {
              file: file.Get('file'),
              items: 'name category price description active evaluation_time',
            }).then(product => {
              product.id = file.Get('file');
              product.locationId = this.location.id;
              return product;
            })
          );
        }
        Promise.all(promises).then(response => {
          resolve(response);
        });
      });
    },

    pickLocation() {
      const formData = {
        locationId: this.location.id,
      };
      this.updateFormData(formData);

      this.$router.push({ name: 'ReservationProduct' });
    },
  },

  created() {
    this.getProductFiles();
  },
};
</script>
