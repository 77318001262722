import { Model } from '../parents/model';

export class ProductModel extends Model {
  static ATTR_ID = 'id';
  static ATTR_NAME = 'name';
  static ATTR_ACTIVE = 'active';
  static ATTR_INDICATION = 'indication';
  static ATTR_CATEGORY = 'category';
  static ATTR_PRICE = 'price';
  static ATTR_DURATION_TIME_MIN = 'duration_min';
  static ATTR_EVAULATION_TIME_MIN = 'evaluation_time';
  static ATTR_DESCRIPTION = 'description';

  get id() {
    return this.attributes[ProductModel.ATTR_ID];
  }

  get active() {
    return isValid(this.attributes[ProductModel.ATTR_ACTIVE]);
  }

  get name() {
    return isValid(this.attributes[ProductModel.ATTR_NAME]);
  }

  get indication() {
    return isValid(this.attributes[ProductModel.ATTR_INDICATION]);
  }

  get category() {
    return isValid(this.attributes[ProductModel.ATTR_CATEGORY]);
  }

  get price() {
    return isValid(this.attributes[ProductModel.ATTR_PRICE]);
  }

  get durationTimeMin() {
    return isValid(this.attributes[ProductModel.ATTR_DURATION_TIME_MIN]);
  }

  get evaulationTimeMin() {
    return isValid(this.attributes[ProductModel.ATTR_EVAULATION_TIME_MIN]);
  }

  get description() {
    return isValid(this.attributes[ProductModel.ATTR_DESCRIPTION]);
  }
}

function isValid(attribute) {
  return attribute ? attribute[0] : null;
}
