<template>
  <div class="position-relative">
    <Loader v-if="!products.length" />
    <ul class="s-l-cards" aria-label="Testovací místa">
      <LocationCardComponent
        v-for="(location, index) in locations"
        :key="index"
        :location="location"
      ></LocationCardComponent>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LocationCardComponent from './LocationCardComponent';
import Loader from '../partials/loader';

import { LocationModel } from '../../common/models/location.model';

import { XiteApiService } from '../../services/xite.api.service';

export default {
  name: 'LocationListComponent',

  components: {
    LocationCardComponent,
    Loader,
  },

  computed: {
    ...mapGetters({
      locations: 'locations/getAllLocations',
      products: 'products/getAllProducts',
    }),
  },

  methods: {
    ...mapActions('locations', ['setLocations']),

    getLocationFiles() {
      if (!this.locations.length) {
        XiteApiService.get('Locations', {
          instance: process.env.VUE_APP_INSTANCE_FILE,
        }).then(files => {
          this.getLocationData(files['item']).then(locations => {
            this.setLocations(locations.map(location => new LocationModel(location)));
          });
        });
      }
    },

    getLocationData(files) {
      let promises = [];
      return new Promise(resolve => {
        for (let file of files) {
          promises.push(
            XiteApiService.get('GetItems', {
              file: file.Get('file'),
              items: 'city location address show_free_slots active',
            }).then(location => {
              location.id = file.Get('file');
              return location;
            })
          );
        }
        Promise.all(promises).then(response => {
          resolve(response);
        });
      });
    },
  },

  created() {
    if (!this.locations.length) {
      this.getLocationFiles();
    }
  },
};
</script>

<style scoped>

</style>
