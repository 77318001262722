import { Model } from '../parents/model';

export class LocationModel extends Model {
  static ATTR_ID = 'id';
  static ATTR_SLUG = 'slug';
  static ATTR_ACTIVE = 'active';
  static ATTR_CITY = 'city';
  static ATTR_LOCATION = 'location';
  static ATTR_ADDRESS = 'address';
  static ATTR_FREE_SLOTS_COUNT = 'show_free_slots';
  static ATTR_FAVOURITE = 'favourite';

  get id() {
    return this.attributes[LocationModel.ATTR_ID];
  }

  get active() {
    return isValid(this.attributes[LocationModel.ATTR_ACTIVE]);
  }

  get city() {
    return isValid(this.attributes[LocationModel.ATTR_CITY]);
  }

  get location() {
    return isValid(this.attributes[LocationModel.ATTR_LOCATION]);
  }

  get address() {
    return isValid(this.attributes[LocationModel.ATTR_ADDRESS]);
  }

  get freeSlotsCount() {
    return isValid(this.attributes[LocationModel.ATTR_FREE_SLOTS_COUNT]);
  }

  get isFavourite() {
    return isValid(this.attributes[LocationModel.ATTR_FAVOURITE]);
  }
}

function isValid(attribute) {
  return attribute ? attribute[0] : null;
}
