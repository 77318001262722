<template>
  <div class="s-l-layout__progress" id="mobprogress">
    <ol class="s-timeline" aria-label="Ukazatal průběhu objednávky">
      <li class="s-timeline__item s-timeline__item--current">
        <span class="s-u-visualy-hidden"> Dokončený krok: </span>
        <a href="javascript:void(0)" class="s-timeline__link" title="Výběr lokality">
          <span class="s-timeline__text"> Výběr lokality </span>
        </a>
      </li>
      <li class="s-timeline__item--disabled">
        <span class="s-u-visualy-hidden"> Aktuální krok: </span>
        <span class="s-timeline__link">
          <span class="s-timeline__text"> Výběr produktu </span>
        </span>
      </li>
      <li class="s-timeline__item s-timeline__item--disabled">
        <span class="s-timeline__link">
          <span class="s-timeline__text"> Výběr termínu </span>
        </span>
      </li>
      <li class="s-timeline__item s-timeline__item--disabled">
        <span class="s-timeline__link">
          <span class="s-timeline__text"> Identifikace </span>
        </span>
      </li>
    </ol>
  </div>
  <div class="s-l-layout__left-side s-u-order-1" id="leftside">
    <h2 class="s-h s-h--secondary">Vyberte svou lokalitu</h2>

    <LocationListComponent></LocationListComponent>
  </div>
</template>

<script>
import LocationListComponent from '../../components/locations/LocationListComponent';

export default {
  name: 'ReservationLocation',

  components: {
    LocationListComponent,
  },
};
</script>
